.center-logo-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  z-index: 10; /* Ensure it's on top */
}

.center-logo {
  width: 150px; /* Adjust as needed */
  height: auto;
  cursor: pointer; /* Makes it clear it's clickable */
}
